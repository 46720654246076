import React from 'react';
import { Box } from '@mui/material';
import SessionsChart from '../Dashboard/SessionsChart';
import SideMenu from '../Dashboard/SideMenu';
import TemplateFrame from '../SignUp/TemplateFrame';
import CssBaseline from '@mui/material/CssBaseline';
import Globe from './Globe/Globe';

const Welcome: React.FC = () => {
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');

  React.useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode as 'light' | 'dark');
    } else {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }

    // Ensure no scrolling on the page
    document.body.style.overflow = 'hidden';
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  return (
    <TemplateFrame mode={mode} toggleColorMode={toggleColorMode}>
      <CssBaseline enableColorScheme />
      {/* Ensure Globe renders independently and doesn't affect the layout */}
      <Box sx={{ mt: 5 }}>
        <Globe />
      </Box>
      
      {/* Layout for SessionsChart and SideMenu */}
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }}>
          <Box sx={{ width: '250px', mr: 2 }}>
            <SideMenu />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <SessionsChart />
          </Box>
        </Box>
      </Box>
    </TemplateFrame>
  );
};

export default Welcome;
