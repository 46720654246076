import "./Globestyles.css";
import createGlobe from "cobe";
import { useEffect, useRef, useState } from "react";
import { useSpring } from 'react-spring';

export default function Globe() {
  // Reference for the canvas element
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const pointerInteracting = useRef<number | null>(null);
  const pointerInteractionMovement = useRef<number>(0);
  
  // Use React Spring for smooth animation
  const [{ r }, api] = useSpring(() => ({
    r: 0,
    config: {
      mass: 1,
      tension: 280,
      friction: 40,
      precision: 0.001,
    },
  }));

  const [isPointerDown, setIsPointerDown] = useState(false); // State for cursor style

  useEffect(() => {
    let phi = 0;
    let width = 0;
    const onResize = () => canvasRef.current && (width = canvasRef.current.offsetWidth);
    window.addEventListener('resize', onResize);
    onResize();
    
    if (canvasRef.current) {
      // Initialize the globe
      const globe = createGlobe(canvasRef.current, {
        devicePixelRatio: 2,
        width: width * 2,
        height: width * 2,
        phi: 0,
        theta: 0.3,
        dark: 1,
        opacity: 0.5,
        diffuse: 0.1,
        scale: 1.1,
        mapSamples: 16000,
        mapBrightness: 3,
        baseColor: [0.4, 0.4, 0.4],
        markerColor: [0, 1, 0],
        glowColor: [0.64, 0.64, 0.64],
        markers: [
          { location: [37.7595, -122.4367], size: 0.03 },
          { location: [40.7128, -74.006], size: 0.03 },
        ],
        onRender: (state) => {
          if (!pointerInteracting.current) {
            phi += 0.001; // Auto-rotation speed
          }
          state.phi = phi + r.get();
          state.width = width * 2;
          state.height = width * 2;
        },
      });
      
      // Cleanup globe on component unmount
      return () => {
        globe.destroy();
        window.removeEventListener('resize', onResize);
      };
    }
  }, [r]);

  return (
    <div className="Globe-container">
      <canvas
        ref={canvasRef}
        onPointerDown={(e) => {
          if (canvasRef.current) {
            setIsPointerDown(true);
            pointerInteracting.current = e.clientX - pointerInteractionMovement.current;
          }
        }}
        onPointerUp={() => {
          if (canvasRef.current) {
            setIsPointerDown(false);
            pointerInteracting.current = null;
          }
        }}
        onPointerOut={() => {
          if (canvasRef.current) {
            setIsPointerDown(false);
            pointerInteracting.current = null;
          }
        }}
        onMouseMove={(e) => {
          if (pointerInteracting.current !== null && canvasRef.current) {
            const delta = e.clientX - pointerInteracting.current;
            pointerInteractionMovement.current = delta;
            api.start({
              r: delta / 200, // Controls the smoothness of dragging rotation
            });
          }
        }}
        onTouchMove={(e) => {
          if (pointerInteracting.current !== null && e.touches[0] && canvasRef.current) {
            const delta = e.touches[0].clientX - pointerInteracting.current;
            pointerInteractionMovement.current = delta;
            api.start({
              r: delta / 100, // Controls the smoothness of dragging rotation for touch
            });
          }
        }}
        style={{
          width: '100%',
          height: '100%',
          cursor: isPointerDown ? 'grabbing' : 'grab', // Conditional cursor change
          contain: 'layout paint size',
          opacity: 1,
          transition: 'opacity 1s ease',
        }}
      />
    </div>
  );
}
